<template>
  <section class="workspace-hybrid column items-center q-pt-md">
    <workload-form-wrapper
      :form-state="workspaceModel"
      :submitting="submitting"
      submit-btn-text="Create Workspace"
      cancel-btn-text="Cancel"
      init-dirty
      @leave-page="redirectToPrevRoute"
      @canceled="onCancel"
      @submit="onSubmit"
    >
      <template v-if="!appStore.isPageLoading">
        <title-and-back-section label="Cluster" :summary="clusterName" back-tooltip="Change cluster" @on-back="onBack" />

        <title-and-back-section
          label="Project"
          :summary="projectName"
          back-tooltip="Change project"
          aid="project-section"
          @on-back="onBack"
        />

        <workload-name-section
          :default-open="!!$route.query.fromCopyId"
          entity-type="workspace"
          :name="workspaceModel.name"
          @update:name="onNameChanged"
          :project-id="+workspaceModel.projectId"
          :cluster-id="workspaceModel.clusterId"
        />
        <hybrid-environment-section
          :render-key="selectedTemplates.environment.renderKey.toString()"
          :selected-name="selectedEnvironmentName"
          :workload-spec="workspaceModel.spec"
          :environment-variable-source-value-options="environmentVariablesSourceValueOptions"
          :policy="policy"
          :cluster-id="workspaceModel.clusterId"
          :support-config-map-as-env-var="supportConfigMapAsEnvVar"
          @load-section-templates="displayEnvironmentsList"
          @update="updateModelSpec"
          @reset="resetEnvironmentToDefault"
          @clear="clearEnvironmentTemplate"
        />
        <hybrid-compute-resource-section
          :selected-name="selectedComputeName"
          :workload-spec="workspaceModel.spec"
          :project-node-types="selectedProjectNodeTypes"
          :node-pools-resources="projectNodePoolsResources"
          :policy="policy"
          :support-multi-fraction-gpu="supportMultiFractionGpu"
          @load-section-templates="displayComputesList"
          @update="workspaceModel.spec = $event"
          @reset="resetComputeToDefault"
          @clear="clearComputeTemplate"
        />

        <volume-section
          should-auto-validate
          :volumes="workspaceModelVolumes || []"
          :storage-classes="storageClasses"
          :policy-rules="policy?.rules?.storage?.pvc"
          :policy-defaults="policy?.defaults?.storage?.pvc?.attributes"
          @update-volumes="updateVolumes"
          :loading-storage-class="loadingStorageClass"
        />

        <hybrid-data-source-section
          :default-opened="shouldOpenDataSourceSection"
          :workload-storage="dataSourceSectionModel"
          :policy-rules="policy?.rules?.storage"
          @load-section-templates="displayDataSourcesList"
          @update="updateModelStorage"
          :secrets="secrets"
        />

        <general-section
          should-auto-validate
          :workload-form-type="EWorkloadFormType.Workspace"
          :general-model="generalSectionModel"
          :show-backoff-limit="supportBackoffLimit"
          :policy-rules="policy?.rules"
          :itemize-defaults="generalSectionItemizeDefaults"
          :show-termination-grace-period="isClusterSupportTerminationGracePeriod"
          @general-model-changed="updateGeneralModel"
        />
      </template>
    </workload-form-wrapper>

    <workload-creation-drawer
      :is-open="isDrawerOpen"
      :comply-to="drawerFilters"
      :entity="drawerDataEntity"
      :policy-details="policyViewDetails"
      :created-entity-id="recentlyCreatedEntityId"
      @close="closeDrawer"
      @select="handleSelection"
      @select-multiple="handleMultiSelection"
      @create-new="onCreateNewSetup"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// Components
import { WorkloadCreationDrawer } from "@/components/workload-creation/workload-creation-drawer";
import { HybridEnvironmentSection } from "@/components/workload-creation/hybrid-environment-section";
import { HybridComputeResourceSection } from "@/components/workload-creation/hybrid-compute-resource-section";
import { TitleAndBackSection } from "@/components/section/title-and-back-section";
import { WorkloadNameSection } from "@/components/section/workload-name-section";
import { WorkloadFormWrapper } from "@/components/workload/workload-form-wrapper";
import { HybridDataSourceSection } from "@/components/workload-creation/hybrid-data-source-section";
import { GeneralSection } from "@/components/section/general-section";
import { VolumeSection } from "@/components/section/volume-section";
// Stores & Services
import { useAuthStore } from "@/stores/auth.store";
import { useAppStore } from "@/stores/app.store";
import { useWorkspaceStore } from "@/stores/workspace.store";
import { useClusterStore } from "@/stores/cluster.store";
import { workloadService } from "@/services/cluster/workload.service/workload.service";
import { orgUnitService } from "@/services/control-plane/org-unit.service/org-unit.service";
import { workspaceService } from "@/services/control-plane/workspace.service/workspace.service";
import { workloadHybridUtil } from "@/utils/workload-hybrid.util/workload-hybrid.util";
import { alertUtil } from "@/utils/alert.util";
import { requestToLeave } from "@/services/infra/router.service/router.service";
import { deepCopy, omit } from "@/utils/common.util";
import { policyUtil } from "@/utils/policy.util";
import { ErrorAlert } from "@/utils/error-alert.util";
// Models
import { AssetKind, Scope, type StringOption } from "@/swagger-models/assets-service-client";
import { WorkloadCreationDrawerEntities } from "@/models/workload-creation-drawer-model";
import { EWorkloadType } from "@/models/workload.model";
import { WORKLOAD_ROUTE_NAMES } from "@/router/workloads.routes/workloads.routes.names";
import { WORKSPACE_ROUTE_NAMES } from "@/router/workspace.routes/workspace.routes.names";
import { getPolicyViewDetails, type IPolicyViewDetails } from "@/utils/policy.util/policy-view.util";
import { EWorkloadFormType } from "@/models/workload.model";
import {
  MIN_CLUSTER_VERSION_FOR_CONFIG_MAP_ENV_VAR,
  MIN_CLUSTER_VERSION_FOR_MULTI_FRACTION_GPU,
  MIN_CLUSTER_VERSION_FOR_TERMINATION_GRACE_PERIOD,
} from "@/common/version.constant";
import type { IUIVolume, TDataSourceKinds } from "@/models/data-source.model";
import type { IAssetsFilter } from "@/models/filter.model";
import type { IUIGeneralSectionModel } from "@/components/section/general-section";
import type { Project } from "@/swagger-models/org-unit-service-client";
import type { IItemizedListItem } from "@/components/common/runai-itemized-list";
import type { THybridFormState, THybridSelectedTemplates } from "@/models/workload-hybrid.model";
import type {
  IUIEnvrionmentFields,
  IUIStorageVolumesExcludedFields,
  IUIWorkspaceCreationRequest,
  IUIWorkspaceSpec,
} from "@/models/workload.model";
import type { ILoadedItemizeDefaults } from "@/models/policy.model";
import {
  PriorityClass,
  type UnifiedPolicyInfoPerReplicaType,
  type UnifiedProjectInfo,
  type UnifiedWorkload,
  type UnifiedWorkloadRequest,
} from "@/swagger-models/workloads-client";
import { workloadUtil } from "@/utils/workload.util";
import { useEnvironmentVariablesSection } from "@/composables/use-environment-variables-section.composable";
import { useStorageClassConfiguration } from "@/composables/use-storage-class-configuration.composable";
import { credentialService } from "@/services/control-plane/credential.service/credential.service";
import type { SecretListInfo } from "@/swagger-models/k8s-objects-tracker-client";

const emptyWorkspaceModel: IUIWorkspaceCreationRequest = {
  name: "",
  projectId: "",
  clusterId: "",
  spec: { connections: [] },
};

const creationRoutes = workloadUtil.getSupportedRoutes(
  WORKSPACE_ROUTE_NAMES.WORKSPACE_HYBRID,
  WORKSPACE_ROUTE_NAMES.WORKSPACE_NEW,
);

export default defineComponent({
  name: "workspace-hybrid",
  components: {
    WorkloadCreationDrawer,
    HybridEnvironmentSection,
    HybridComputeResourceSection,
    TitleAndBackSection,
    WorkloadNameSection,
    WorkloadFormWrapper,
    HybridDataSourceSection,
    GeneralSection,
    VolumeSection,
  },
  data() {
    return {
      appStore: useAppStore(),
      authStore: useAuthStore(),
      workspaceStore: useWorkspaceStore(),
      clusterStore: useClusterStore(),
      isDrawerOpen: false,
      drawerDataEntity: null as WorkloadCreationDrawerEntities | null,
      EWorkloadFormType,
      loadingDrawerData: false,
      submitting: false as boolean,
      recentlyCreatedEntityId: null as string | null,

      workspaceModel: emptyWorkspaceModel as IUIWorkspaceCreationRequest,
      policy: undefined as UnifiedPolicyInfoPerReplicaType | undefined | null,
      policyViewDetails: {} as IPolicyViewDetails,
      selectedProject: null as Project | null,
      selectedProjectNodeTypes: [] as string[],
      projectInfo: {} as UnifiedProjectInfo,
      projectNodePoolsResources: [] as StringOption[],
      selectedTemplates: {
        [WorkloadCreationDrawerEntities.Environment]: {
          name: "",
          defaultData: null as IUIEnvrionmentFields | null,
          clearedState: null as IUIEnvrionmentFields | null,
          renderKey: 1001,
        },
        [WorkloadCreationDrawerEntities.DataSource]: {
          name: "",
          defaultData: null as IUIWorkspaceSpec["storage"] | null,
          clearedState: null as IUIWorkspaceSpec["storage"] | null,
        },
        [WorkloadCreationDrawerEntities.Compute]: {
          name: "",
          defaultData: null as IUIWorkspaceSpec["compute"] | null,
          clearedState: null as IUIWorkspaceSpec["compute"] | null,
        },
      } as THybridSelectedTemplates,
      secrets: [] as SecretListInfo[],
    };
  },
  setup() {
    const { environmentVariablesSourceValueOptions, loadSourceValueOptions, validateEnvironmentVariables } =
      useEnvironmentVariablesSection();

    const { storageClasses, loadingStorageClass, loadStorageClasses, loadStorageClassConfiguration } =
      useStorageClassConfiguration("workload");

    return {
      environmentVariablesSourceValueOptions,
      loadSourceValueOptions,
      validateEnvironmentVariables,

      storageClasses,
      loadingStorageClass,
      loadStorageClasses,
      loadStorageClassConfiguration,
    };
  },
  async created() {
    const { fromCopyId, templateId, projectId, clusterId, kind, createdEntityId, workspaceName } = this.$route.query;

    this.handleCreatedAssets(kind?.toString(), createdEntityId?.toString());

    const formState = this.workspaceStore.getFlatFormState();
    if (formState) this.loadFormState();
    else
      await this.initWorkspaceModel(
        fromCopyId?.toString(),
        projectId?.toString(),
        clusterId?.toString(),
        workspaceName?.toString(),
        templateId?.toString(),
      );

    await Promise.all([
      this.loadStorageClasses(this.workspaceModel.clusterId, [
        this.workspaceModel.spec.storage?.volumes?.[0]?.claimInfo?.storageClass,
      ]),
      this.loadSourceValueOptions(true, this.workspaceModel.projectId),
      this.loadSecrets(),
    ]);
    this.validateEnvironmentVariables(this.workspaceModel.spec.environmentVariables || []);
    this.appStore.setPageLoading(false);
  },
  computed: {
    shouldOpenDataSourceSection(): boolean {
      return (
        !!this.$route.query.createdEntityId &&
        [
          AssetKind.HostPath,
          AssetKind.Nfs,
          AssetKind.Pvc,
          AssetKind.Git,
          AssetKind.S3,
          AssetKind.ConfigMap,
          AssetKind.SecretVolume,
        ].includes(this.$route.query.kind as TDataSourceKinds)
      );
    },
    selectedEnvironmentName(): string {
      return this.selectedTemplates[WorkloadCreationDrawerEntities.Environment].name;
    },
    selectedComputeName(): string {
      return this.selectedTemplates[WorkloadCreationDrawerEntities.Compute].name;
    },
    selectedDataSourceName(): string {
      return this.selectedTemplates[WorkloadCreationDrawerEntities.DataSource].name;
    },
    drawerFilters(): IAssetsFilter {
      return {
        projectId: +this.workspaceModel.projectId,
        complyToProject: +this.workspaceModel.projectId,
        complyToWorkloadType: EWorkloadType.Workspace,
        isWorkspace: true,
      };
    },
    clusterName(): string {
      return this.clusterStore.clusterList.find((cluster) => cluster.uuid === this.workspaceModel.clusterId)?.name || "";
    },
    projectName(): string {
      return this.projectInfo.name || "-";
    },
    generalSectionModel(): IUIGeneralSectionModel {
      return {
        allowOverQuota: this.workspaceModel?.spec?.priorityClass === PriorityClass.InteractivePreemptible || false,
        autoDeletionTimeAfterCompletionSeconds: Number.isInteger(
          this.workspaceModel.spec?.autoDeletionTimeAfterCompletionSeconds,
        )
          ? this.workspaceModel.spec?.autoDeletionTimeAfterCompletionSeconds
          : null,
        annotations: (this.workspaceModel.spec?.annotations as IItemizedListItem[]) || undefined,
        labels: (this.workspaceModel.spec?.labels as IItemizedListItem[]) || undefined,
        backoffLimit: this.workspaceModel.spec?.backoffLimit || null,
        terminationGracePeriodSeconds: this.workspaceModel.spec?.terminationGracePeriodSeconds || null,
      };
    },
    generalSectionItemizeDefaults(): ILoadedItemizeDefaults {
      return {
        environmentVariables: this.policy?.defaults?.environmentVariables,
        annotations: this.policy?.defaults?.annotations,
        labels: this.policy?.defaults?.labels,
        tolerations: this.policy?.defaults?.tolerations,
      };
    },
    supportBackoffLimit(): boolean {
      return this.clusterStore.isClusterVersionSupportBackoffLimit(this.workspaceModel.clusterId);
    },
    isClusterSupportTerminationGracePeriod(): boolean {
      return this.clusterStore.isClusterVersionSufficient(
        this.workspaceModel.clusterId,
        MIN_CLUSTER_VERSION_FOR_TERMINATION_GRACE_PERIOD,
      );
    },
    dataSourceSectionModel(): IUIStorageVolumesExcludedFields | undefined {
      if (this.workspaceModel.spec.storage?.volumes) return omit(this.workspaceModel.spec.storage, ["volumes"]);
      return this.workspaceModel.spec.storage || undefined;
    },
    workspaceModelVolumes(): IUIVolume[] {
      return this.workspaceModel.spec.storage?.volumes || [];
    },
    supportMultiFractionGpu(): boolean {
      return this.clusterStore.isClusterVersionSufficient(
        this.workspaceModel.clusterId || "",
        MIN_CLUSTER_VERSION_FOR_MULTI_FRACTION_GPU,
      );
    },
    supportConfigMapAsEnvVar(): boolean {
      return this.clusterStore.isClusterVersionSufficient(
        this.workspaceModel.clusterId || "",
        MIN_CLUSTER_VERSION_FOR_CONFIG_MAP_ENV_VAR,
      );
    },
  },
  methods: {
    onNameChanged(name: string) {
      this.workspaceModel.name = name;
    },
    updateModelSpec(spec: IUIWorkspaceCreationRequest["spec"]): void {
      this.workspaceModel.spec = spec;
    },
    updateModelStorage(storage: IUIStorageVolumesExcludedFields): void {
      this.workspaceModel.spec.storage = { volumes: this.workspaceModel.spec.storage?.volumes, ...storage };
    },
    closeDrawer(): void {
      this.isDrawerOpen = false;
      this.drawerDataEntity = null;
    },
    openDrawer(entity: WorkloadCreationDrawerEntities): void {
      this.drawerDataEntity = entity;
      this.isDrawerOpen = true;
    },
    displayEnvironmentsList(): void {
      this.openDrawer(WorkloadCreationDrawerEntities.Environment);
    },
    displayComputesList(): void {
      this.openDrawer(WorkloadCreationDrawerEntities.Compute);
    },
    displayDataSourcesList(): void {
      this.openDrawer(WorkloadCreationDrawerEntities.DataSource);
    },
    resetEnvironmentToDefault(): void {
      if (!this.selectedTemplates[WorkloadCreationDrawerEntities.Environment].defaultData) return;

      this.workspaceModel = workloadHybridUtil.combineWorkloadModelWithEnvironmentDetails(
        this.workspaceModel,
        this.selectedTemplates[WorkloadCreationDrawerEntities.Environment].defaultData,
      );
      this.updateEnvironmentRenderKey();
    },
    resetComputeToDefault(): void {
      if (!this.selectedTemplates[WorkloadCreationDrawerEntities.Compute].defaultData) return;

      this.workspaceModel = workloadHybridUtil.combineWorkloadModelWithComputeDetails(
        this.workspaceModel,
        this.selectedTemplates[WorkloadCreationDrawerEntities.Compute].defaultData,
      );
    },
    resetDataSourcesToDefault(): void {
      if (!this.selectedTemplates[WorkloadCreationDrawerEntities.DataSource].defaultData) return;

      this.workspaceModel = workloadHybridUtil.combineWorkloadModelWithStorageDetails(
        this.workspaceModel,
        this.selectedTemplates[WorkloadCreationDrawerEntities.DataSource].defaultData,
      );
    },
    clearEnvironmentTemplate(): void {
      this.selectedTemplates[WorkloadCreationDrawerEntities.Environment].name = "";
      this.selectedTemplates[WorkloadCreationDrawerEntities.Environment].defaultData = null;

      if (!this.selectedTemplates[WorkloadCreationDrawerEntities.Environment].clearedState) return;

      this.workspaceModel = workloadHybridUtil.combineWorkloadModelWithEnvironmentDetails(
        this.workspaceModel,
        this.selectedTemplates[WorkloadCreationDrawerEntities.Environment].clearedState,
      );
      this.updateEnvironmentRenderKey();
    },
    clearComputeTemplate(): void {
      this.selectedTemplates[WorkloadCreationDrawerEntities.Compute].name = "";
      this.selectedTemplates[WorkloadCreationDrawerEntities.Compute].defaultData = null;

      if (!this.selectedTemplates[WorkloadCreationDrawerEntities.Compute].clearedState) return;

      this.workspaceModel = workloadHybridUtil.combineWorkloadModelWithComputeDetails(
        this.workspaceModel,
        this.selectedTemplates[WorkloadCreationDrawerEntities.Compute].clearedState,
      );
    },
    clearDataSourceTemplate(): void {
      this.selectedTemplates[WorkloadCreationDrawerEntities.DataSource].name = "";
      this.selectedTemplates[WorkloadCreationDrawerEntities.DataSource].defaultData = null;

      if (!this.selectedTemplates[WorkloadCreationDrawerEntities.DataSource].clearedState) return;

      this.workspaceModel = workloadHybridUtil.combineWorkloadModelWithStorageDetails(
        this.workspaceModel,
        this.selectedTemplates[WorkloadCreationDrawerEntities.DataSource].clearedState,
      );
    },
    createSectionsClearedStates(): void {
      const base = deepCopy(this.workspaceModel);
      this.selectedTemplates[WorkloadCreationDrawerEntities.Environment].clearedState =
        workloadHybridUtil.getRelevantEnvironmentFields(base);
      this.selectedTemplates[WorkloadCreationDrawerEntities.Compute].clearedState =
        workloadHybridUtil.getRelevantComputeFields(base);
      this.selectedTemplates[WorkloadCreationDrawerEntities.DataSource].clearedState =
        workloadHybridUtil.getRelevantStorageFields(base);
    },
    handleCreatedAssets(kind?: string, createdEntityId?: string): void {
      if (!kind || !createdEntityId) return;

      this.recentlyCreatedEntityId = createdEntityId;
      switch (kind) {
        case AssetKind.Environment:
          this.displayEnvironmentsList();
          break;
        case AssetKind.Compute:
          this.displayComputesList();
          break;
        case AssetKind.HostPath:
        case AssetKind.Nfs:
        case AssetKind.Git:
        case AssetKind.S3:
        case AssetKind.Pvc:
        case AssetKind.ConfigMap:
        case AssetKind.SecretVolume:
          this.displayDataSourcesList();
          break;
        default:
          this.recentlyCreatedEntityId = null;
      }
    },
    saveFormState(): void {
      const formState: THybridFormState<IUIWorkspaceCreationRequest> = {
        projectInfo: this.projectInfo,
        projectNodePoolsResources: this.projectNodePoolsResources,
        selectedTemplates: this.selectedTemplates,
        policy: this.policy,
        policyViewDetails: this.policyViewDetails,
        selectedProject: this.selectedProject,
        selectedProjectNodeTypes: this.selectedProjectNodeTypes,
        workloadModel: this.workspaceModel,
      };
      this.workspaceStore.setFlatFormState(deepCopy(formState));
    },
    loadFormState(): void {
      const formState = this.workspaceStore.getFlatFormState();
      if (!formState) return;
      this.projectInfo = formState?.projectInfo;
      this.projectNodePoolsResources = formState?.projectNodePoolsResources;
      this.selectedTemplates = formState?.selectedTemplates;
      this.policy = formState?.policy;
      this.policyViewDetails = formState?.policyViewDetails;
      this.selectedProject = formState?.selectedProject;
      this.selectedProjectNodeTypes = formState?.selectedProjectNodeTypes;
      this.workspaceModel = formState?.workloadModel;
    },
    clearFormState(): void {
      this.workspaceStore.clearFlatFormState();
    },
    async loadSecrets(): Promise<void> {
      try {
        this.secrets = await credentialService.listExistingSecrets(
          Scope.Project,
          this.workspaceModel.projectId,
          undefined,
          "all",
        );
      } catch (error: unknown) {
        console.error("Failed to load secrets:\n", error);
        this.$q.notify(alertUtil.getError("Failed to load secrets"));
      }
    },
    async loadProjectById(projectId: string): Promise<Project | void> {
      try {
        this.selectedProject = await orgUnitService.getProject(projectId);
      } catch (error: unknown) {
        console.error(error);
        this.appStore.setFallback(true);
      }
    },
    async initWorkspaceModel(
      workloadId?: string,
      projectId?: string,
      clusterId?: string,
      workspaceName?: string,
      templateId?: string,
    ): Promise<void> {
      try {
        const unifiedWorkload: UnifiedWorkload = await this.getUnifiedWorkspace({
          workloadId,
          projectId,
          clusterId,
          assetId: templateId,
        });

        this.policy = unifiedWorkload.policyInfo?.worker;
        this.policyViewDetails = getPolicyViewDetails(this.policy || {});

        this.projectInfo = unifiedWorkload.projectInfo || {};
        this.projectNodePoolsResources = unifiedWorkload.policyInfo?.worker?.rules?.nodePools?.options || [];

        const uiModel = workloadHybridUtil.getHybridWorkspaceModel(unifiedWorkload);
        this.workspaceModel = workloadHybridUtil.setWorkspaceUIDefaults(uiModel, this.authStore.isSSO, this.policy);

        if (!workloadId && !this.workspaceModel.spec.nodePools?.length && this.projectNodePoolsResources?.[0]?.value)
          this.workspaceModel.spec.nodePools = [this.projectNodePoolsResources[0].value];

        this.createSectionsClearedStates();
        // need to take for account the id coming from copy
        if (projectId) this.workspaceModel.projectId = projectId;
        if (clusterId) this.workspaceModel.clusterId = clusterId;
        if (workspaceName) this.workspaceModel.name = workspaceName;
      } catch (e) {
        this.$q.notify(alertUtil.getError("Failed to load workspace model"));
        console.error("Failed to get unified workspace:\n", e);
        this.appStore.setFallback(true);
      }
    },
    async handleSelection(itemIdAndName: { id: string; name: string }): Promise<void> {
      if (!this.drawerDataEntity) return;

      try {
        await this.combineModels(itemIdAndName.id);
        this.selectedTemplates[this.drawerDataEntity].name = itemIdAndName.name;
        this.closeDrawer();
      } catch (e) {
        this.$q.notify(alertUtil.getError("Failed to select asset template"));
        console.error("Failed to select asset template:\n", e);
      }
    },
    async handleMultiSelection(selectedItems: { id: string; name: string }[]): Promise<void> {
      if (!this.drawerDataEntity) return;

      try {
        await this.combineModels(selectedItems.map((item) => item.id));

        this.closeDrawer();
      } catch (e) {
        this.$q.notify(alertUtil.getError("Failed to select asset template"));
        console.error("Failed to select asset template:\n", e);
      }
    },
    updateEnvironmentRenderKey(): void {
      this.selectedTemplates[WorkloadCreationDrawerEntities.Environment].renderKey++;
    },
    async combineModels(assetId: string | string[]): Promise<void> {
      const requestOptions: UnifiedWorkloadRequest = {
        clusterId: this.workspaceModel.clusterId,
        projectId: this.workspaceModel.projectId,
      };
      Array.isArray(assetId) ? (requestOptions.assetIds = assetId) : (requestOptions.assetId = assetId);
      const modelWithItemDetailsFlattened = await this.getUnifiedWorkspace(requestOptions);

      const uiModel = workloadHybridUtil.getHybridWorkspaceModel(modelWithItemDetailsFlattened);
      switch (this.drawerDataEntity) {
        case WorkloadCreationDrawerEntities.Environment: {
          const envFields = workloadHybridUtil.getRelevantEnvironmentFields(uiModel);
          this.workspaceModel = workloadHybridUtil.combineWorkloadModelWithEnvironmentDetails(
            this.workspaceModel,
            envFields,
          );
          this.selectedTemplates.environment.defaultData = envFields;
          this.updateEnvironmentRenderKey();
          break;
        }
        case WorkloadCreationDrawerEntities.Compute: {
          const computeFields = workloadHybridUtil.getRelevantComputeFields(uiModel);
          this.workspaceModel = workloadHybridUtil.combineWorkloadModelWithComputeDetails(
            this.workspaceModel,
            computeFields,
          );
          this.selectedTemplates[WorkloadCreationDrawerEntities.Compute].defaultData = computeFields;
          break;
        }
        case WorkloadCreationDrawerEntities.DataSource: {
          const storageFields = workloadHybridUtil.getRelevantStorageFields(uiModel);
          this.selectedTemplates[WorkloadCreationDrawerEntities.DataSource].defaultData = storageFields;
          this.workspaceModel = workloadHybridUtil.combineWorkloadModelWithStorageDetails(
            this.workspaceModel,
            storageFields,
          );
          break;
        }
      }
    },
    async getUnifiedWorkspace(options?: UnifiedWorkloadRequest): Promise<UnifiedWorkload> {
      const request: UnifiedWorkloadRequest = {
        clusterId: options?.clusterId,
        projectId: options?.projectId,
        workloadType: EWorkloadType.Workspace,
        workloadId: options?.workloadId,
        assetId: options?.assetId,
        assetIds: options?.assetIds,
      };
      return await workloadService.getUnifiedWorkload(request);
    },
    async onSubmit(): Promise<void> {
      try {
        this.submitting = true;

        this.workspaceModel.spec = policyUtil.cleanupFlatItemizeBeforeSubmission(this.workspaceModel.spec, this.policy);

        const tokenData = {
          uid: this.authStore.getUID,
          gid: this.authStore.getGID,
          supplementalGroups: this.authStore.getSupplementaryGroups,
        };

        const request = workloadHybridUtil.prepareWorkspaceFlatRequest(this.workspaceModel, tokenData);
        await workspaceService.createWorkspaceFlat(request);

        this.$q.notify(alertUtil.getSuccess(`Workspace ${this.workspaceModel.name} created`));
        this.redirectToPrevRoute(true);
      } catch (error: unknown) {
        const errorAlert = new ErrorAlert({
          generalMessage: ErrorAlert.failedCreateMessage("workspace"),
        });
        this.$q.notify(errorAlert.getNotification(error));
        console.error("Failed to create workspace:\n", error);
      } finally {
        this.submitting = false;
      }
    },
    async onCancel(): Promise<void> {
      const allowToLeave: boolean = await requestToLeave();
      if (allowToLeave) {
        this.redirectToPrevRoute();
      }
    },
    redirectToPrevRoute(fromSubmit?: boolean): void {
      this.clearFormState();

      const query = fromSubmit ? { clusterId: this.workspaceModel.clusterId } : undefined;
      this.$router.push({ name: WORKLOAD_ROUTE_NAMES.WORKLOAD_INDEX, query });
    },
    onBack(): void {
      this.clearFormState();

      this.$router.push({ name: WORKSPACE_ROUTE_NAMES.WORKSPACE_NEW });
    },
    updateGeneralModel(generalModel: IUIGeneralSectionModel): void {
      this.workspaceModel = {
        ...this.workspaceModel,
        spec: {
          ...this.workspaceModel.spec,
          annotations: generalModel.annotations,
          labels: generalModel.labels,
          backoffLimit: generalModel.backoffLimit,
          terminationGracePeriodSeconds: generalModel.terminationGracePeriodSeconds,
          autoDeletionTimeAfterCompletionSeconds: generalModel.autoDeletionTimeAfterCompletionSeconds,
          priorityClass: generalModel.allowOverQuota ? PriorityClass.InteractivePreemptible : undefined,
        },
      };
    },
    async updateVolumes(volumes: IUIVolume[]): Promise<void> {
      const newStorageClass = volumes?.[0]?.claimInfo?.storageClass;
      const oldStorageClass = this.workspaceModel.spec.storage?.volumes?.[0]?.claimInfo?.storageClass;

      if (!this.workspaceModel.spec.storage) {
        this.workspaceModel.spec.storage = {
          volumes: volumes,
        };
      } else {
        this.workspaceModel.spec.storage.volumes = volumes;
      }

      // If the storage class hasn't changed there is no need to do any changes
      if (!newStorageClass || newStorageClass === oldStorageClass) return;

      if (this.workspaceModel.spec.storage?.volumes?.[0]) {
        this.workspaceModel.spec.storage.volumes[0].claimInfo = await this.loadStorageClassConfiguration(
          this.workspaceModel.clusterId,
          newStorageClass,
        );
      }
    },
    onCreateNewSetup(type: string): void {
      switch (this.drawerDataEntity) {
        case WorkloadCreationDrawerEntities.Environment:
          this.onCreateNewEnvironment();
          break;
        case WorkloadCreationDrawerEntities.Compute:
          this.onCreateNewComputeResource();
          break;
        case WorkloadCreationDrawerEntities.DataSource:
          this.onCreateNewDataSource(type);
          break;
      }
    },
    onCreateNewEnvironment(): void {
      if (!creationRoutes) return;
      this.saveFormState();
      this.$router.push({
        name: creationRoutes.environment.name,
        query: {
          ...creationRoutes.environment.query,
          projectId: this.workspaceModel.projectId,
          scope: Scope.Project,
          workloadType: EWorkloadFormType.Workspace,
        },
      });
    },
    onCreateNewComputeResource(): void {
      if (!creationRoutes) return;
      this.saveFormState();
      this.$router.push({
        name: creationRoutes.compute.name,
        query: {
          ...creationRoutes.compute.query,
          projectId: this.workspaceModel.projectId,
          scope: Scope.Project,
        },
      });
    },
    onCreateNewDataSource(selectedDataSourceType: string): void {
      if (!creationRoutes) return;
      this.saveFormState();
      this.$router.push({
        name: creationRoutes.datasource.pages[selectedDataSourceType],
        query: { projectId: this.workspaceModel.projectId, scope: Scope.Project },
      });
    },
  },
});
</script>
