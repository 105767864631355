export enum EFeatureToggle {
  /*** General ***/
  ShowNewLayout = "release-19405-show-new-ui-navigation",
  /*** Phoenix ***/
  /*** Lemur ***/
  ShowPolicyForInference = "release-23112-show-policy-for-inference",
  ShowCredentialsForHFInference = "release-23112-show-credentials-for-hf-inference",
  ShowFlatInference = "release-RUN-25482-show-flat-inference",
  ShowModelStoreInHuggingFace = "release-RUN-24954-show-model-store-in-hugging-face",
  /*** Viper ***/
  ShowUpdateAssetEnabled = "release-23268-show-asset-editing",
  ShowMigProfilesDisabled = "release-19264-show-mig-profile-deprecation",
  ShowHybridSubmission = "release-19225-show-hybrid-submission-form",
  /*** Suricata ***/
}
