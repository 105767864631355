import { unleashConfigService } from "./unleash-config.service";
import { EFeatureToggle } from "@/services/infra/unleash.service/unleash.model";

export const unleashService = {
  shouldShowNewLayout,
  showPolicyForInference,
  isMigProfilesDisabled,
  isUpdateAssetEnabled,
  isFeatureEnabled,
  showCredentialsForHFInference,
  showHybridSubmission,
  showFlatInference,
  showModelStoreInHuggingFace,
};

function isFeatureEnabled(featureToggle: EFeatureToggle): boolean {
  return unleashConfigService.isFeatureEnabled(featureToggle);
}

function shouldShowNewLayout(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowNewLayout);
}

function showPolicyForInference(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowPolicyForInference);
}

function isMigProfilesDisabled(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowMigProfilesDisabled);
}

function isUpdateAssetEnabled(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowUpdateAssetEnabled);
}

function showCredentialsForHFInference(): boolean {
  return isFeatureEnabled(EFeatureToggle.ShowCredentialsForHFInference);
}

function showHybridSubmission(): boolean {
  return isFeatureEnabled(EFeatureToggle.ShowHybridSubmission);
}

function showModelStoreInHuggingFace(): boolean {
  return isFeatureEnabled(EFeatureToggle.ShowModelStoreInHuggingFace);
}

function showFlatInference(): boolean {
  return isFeatureEnabled(EFeatureToggle.ShowFlatInference);
}
