<template>
  <hybrid-section-wrapper
    aid="hybrid-data-source-section"
    class="hybrid-data-source-section"
    label="Data sources"
    @load-section-templates="$emit('load-section-templates')"
    :default-opened="true"
  >
    <template #subheader>
      <span>{{ dataSourceSummary }}</span>
    </template>

    <div>Set the data sources your workload needs to access</div>
    <q-field
      v-if="tabs.length"
      class="q-my-md form-hint no-padding"
      :model-value="isValidationError"
      :rules="[isIncomplete]"
    />
    <section class="row items-end no-wrap q-mt-md">
      <q-tabs class="data-source-tabs q-mb-sm underline" :model-value="defaultTabsValue" indicator-color="transparent">
        <q-tab @click.stop class="add-one-off-tab">
          <q-icon name="fa-solid fa-plus" size="xs"></q-icon>
          <q-tooltip anchor="top middle" self="bottom middle">Add a one-off data source setup</q-tooltip>
          <data-source-dropdown :allowed-options="allowedDataSourceTypes" @data-source-selected="addOneOffStorage" />
        </q-tab>
      </q-tabs>
      <q-tabs
        v-model="selectedId"
        active-color="primary"
        inline-label
        outside-arrows
        align="left"
        class="data-source-tabs q-mb-sm underline flex-1"
      >
        <q-tab v-for="tab in tabs" class="tab row items-center" :name="tab.id" :key="tab.id">
          <runai-svg-icon v-if="tab.icon" :name="tab.icon" size="24px" class="q-mr-sm"></runai-svg-icon>
          <span class="q-pr-sm text-weight-medium">{{ assetKindToLabelMap[tab.label as AssetKind] }}</span>
          <q-icon v-if="tab.isInvalid" class="q-mr-sm" name="fa-regular fa-circle-exclamation" color="red" size="16px" />
          <q-btn
            v-if="tab.removeable"
            aid="remove-storage-btn"
            round
            color="grey-4"
            text-color="black-70"
            unelevated
            size="sm"
            icon="fa-regular fa-xmark"
            class="close-btn"
            @click.stop="removeStorage(tab.id, tab.label)"
          />
        </q-tab>
      </q-tabs>
    </section>

    <host-path-instance-section
      v-if="selectedHostPath"
      :key="selectedHostPath.id"
      :model-value="selectedHostPath"
      @update:model-value="updateHostPath"
      :policy-rules="policyRules?.hostPath?.attributes"
    />
    <nfs-instance-section
      v-else-if="selectedNfs"
      :key="selectedNfs.id"
      :model-value="selectedNfs"
      @update:model-value="updateNfs"
      :policy-rules="policyRules?.nfs?.attributes"
    />
    <s3-instance-section
      v-else-if="selectedS3"
      :key="selectedS3.id"
      :model-value="selectedS3"
      :secrets="s3Secrets"
      @update:model-value="updateS3"
      :policy-rules="policyRules?.s3?.attributes"
    />

    <git-instance-section
      v-else-if="selectedGit"
      :key="selectedGit.id"
      :model-value="selectedGit"
      :secrets="gitSecrets"
      :support-git-sync-one-parameter="true"
      @update:model-value="updateGit"
      :policy-rules="policyRules?.git?.attributes"
    />

    <secret-volume-instance-section
      v-else-if="selectedSecretVolume"
      :key="selectedSecretVolume.id"
      :model-value="selectedSecretVolume"
      :secrets="[selectedSecretVolume.secret || '']"
      allow-only-container-path
      :loading-secrets="false"
      @update:model-value="updateSecretVolume"
    />

    <config-map-instance-section
      v-else-if="selectedConfigMap"
      :key="selectedConfigMap.id"
      :model-value="selectedConfigMap"
      :config-map-names="[selectedConfigMap.configMap || '']"
      allow-only-container-path
      :loading-config-map-names="false"
      @update:model-value="updateConfigMap"
    />

    <pvc-instance-section
      v-else-if="selectedPvc"
      :key="selectedPvc.id"
      :model-value="selectedPvc"
      :loading-pvc-claim-names="false"
      :pvc-claim-names="[selectedPvc.claimName || '']"
      allow-only-container-path
      @update:model-value="updatePvc"
    />

    <div v-if="!tabs?.length" class="q-my-lg column items-center text-italic text-black-70">
      <q-icon size="55px" name="fa-light fa-database" class="q-mb-md" color="black-54"></q-icon>
      <div class="text-black-54">No data sources yet.</div>
      <div class="text-black-54">Click the button above to load existing setups</div>
    </div>
  </hybrid-section-wrapper>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
// Components
import { HybridSectionWrapper } from "../hybrid-section-wrapper";
import { NfsInstanceSection } from "./nfs-instance-section";
import { HostPathInstanceSection } from "./host-path-instance-section";
import { S3InstanceSection } from "./s3-instance-section";
import { PvcInstanceSection } from "./pvc-instance-section";
import { GitInstanceSection } from "@/components/workload-creation/hybrid-data-source-section/git-instance-section";
import { SecretVolumeInstanceSection } from "./secret-volume-instance-section";
import { ConfigMapInstanceSection } from "./config-map-instance-section";
import { RunaiSvgIcon } from "@/components/common/runai-svg-icon";
import { DataSourceDropdown } from "@/components/data-source/data-source-dropdown";
// Models
import { AssetKind, type StorageFieldsRules } from "@/swagger-models/assets-service-client";
import { DATA_SOURCE_ICONS_NAMES } from "@/common/icons.constant";
import {
  type IUIConfigMapInstance,
  type IUIGitInstance,
  type IUIHostPathInstance,
  type IUINfsInstance,
  type IUIPvcInstance,
  type IUIS3Instance,
  type IUISecretVolumeInstance,
  type IUIStorageVolumesExcludedFields,
} from "@/models/workload.model";
import type { ITabProps } from "@/components/section/compute-resource-section/container-path-override/container-path-override.model";
import { isNotEmpty, isValidDirectoryPath, isValidUrl } from "@/common/form.validators";
import { errorMessages } from "@/common/error-message.constant";
import type { TDataSourceKinds } from "@/models/data-source.model";
import { DATA_SOURCE_KIND_TO_CONTAINER_KEY, DATA_SOURCES_TYPES_TO_NAMES } from "@/models/data-source.model";
import { makeId } from "@/utils/common.util";
import { SecretType, type SecretListInfo } from "@/swagger-models/k8s-objects-tracker-client";

export default defineComponent({
  name: "hybrid-data-source-section",
  components: {
    HybridSectionWrapper,
    NfsInstanceSection,
    S3InstanceSection,
    PvcInstanceSection,
    HostPathInstanceSection,
    GitInstanceSection,
    SecretVolumeInstanceSection,
    ConfigMapInstanceSection,
    RunaiSvgIcon,
    DataSourceDropdown,
  },
  emits: ["load-section-templates", "update"],
  props: {
    workloadStorage: {
      type: Object as PropType<IUIStorageVolumesExcludedFields>,
      default: () => ({}),
    },
    secrets: {
      type: Array as PropType<SecretListInfo[]>,
      required: true,
    },
    policyRules: {
      type: [Object, null] as PropType<StorageFieldsRules | null>,
      required: false,
    },
  },
  data() {
    return {
      assetKindToCmpMap: {
        [AssetKind.Nfs]: NfsInstanceSection,
        [AssetKind.S3]: S3InstanceSection,
        [AssetKind.HostPath]: HostPathInstanceSection,
        [AssetKind.Git]: GitInstanceSection,
        [AssetKind.SecretVolume]: SecretVolumeInstanceSection,
        [AssetKind.ConfigMap]: ConfigMapInstanceSection,
        [AssetKind.Pvc]: PvcInstanceSection,
      },
      assetKindToLabelMap: {
        [AssetKind.Nfs]: "NFS",
        [AssetKind.S3]: "S3",
        [AssetKind.HostPath]: "Host path",
        [AssetKind.Git]: "Git",
        [AssetKind.SecretVolume]: "Secret",
        [AssetKind.ConfigMap]: "Config map",
        [AssetKind.Pvc]: "PVC",
      } as Record<AssetKind, string>,
      selectedId: null as string | null,
      dataSourceIconNames: DATA_SOURCE_ICONS_NAMES,
      defaultTabsValue: null,
    };
  },
  computed: {
    s3Secrets(): string[] {
      return this.secrets.filter((secret) => secret.type === SecretType.AccessKey).map((secret) => secret.name);
    },
    gitSecrets(): string[] {
      return this.secrets.filter((secret) => secret.type === SecretType.Password).map((secret) => secret.name);
    },
    allowedDataSourceTypes(): Set<AssetKind> {
      const options = new Set([AssetKind.Nfs, AssetKind.HostPath, AssetKind.S3, AssetKind.Git] as Array<AssetKind>);

      const rules: Record<keyof StorageFieldsRules, AssetKind> = {
        nfs: AssetKind.Nfs,
        hostPath: AssetKind.HostPath,
        s3: AssetKind.S3,
        git: AssetKind.Git,
        secretVolume: AssetKind.SecretVolume,
        configMapVolume: AssetKind.ConfigMap,
        pvc: AssetKind.Pvc,
        dataVolume: AssetKind.Pvc,
      };

      for (const key of Object.keys(rules) as Array<keyof StorageFieldsRules>) {
        if (this.policyRules?.[key]?.instances?.canAdd === false) {
          options.delete(rules[key]);
        }
      }

      return options;
    },
    dataSourceSummary(): string {
      let summary = "";
      if (this.tabs.length > 0) {
        summary = `${this.assetKindToLabelMap[this.tabs[0].label as AssetKind]}`;
      }
      if (this.tabs.length > 1) {
        return summary + ` + ${this.tabs.length - 1} more`;
      }
      if (this.tabs.length === 0) {
        return "None";
      }
      return summary;
    },
    selectedNfs(): IUINfsInstance | undefined {
      return this.workloadStorage.nfs?.find((ds) => ds.id === this.selectedId);
    },
    selectedHostPath(): IUIHostPathInstance | undefined {
      return this.workloadStorage.hostPath?.find((ds) => ds.id === this.selectedId);
    },
    selectedS3(): IUIS3Instance | undefined {
      return this.workloadStorage.s3?.find((ds) => ds.id === this.selectedId);
    },
    selectedGit(): IUIGitInstance | undefined {
      return this.workloadStorage.git?.find((ds) => ds.id === this.selectedId);
    },
    selectedSecretVolume(): IUISecretVolumeInstance | undefined {
      return this.workloadStorage.secretVolume?.find((ds) => ds.id === this.selectedId);
    },
    selectedConfigMap(): IUIConfigMapInstance | undefined {
      return this.workloadStorage.configMapVolume?.find((ds) => ds.id === this.selectedId);
    },
    selectedPvc(): IUIPvcInstance | undefined {
      return this.workloadStorage.pvc?.find((ds) => ds.id === this.selectedId);
    },
    tabs(): ITabProps[] {
      return Object.values(this.workloadStorage)
        .flat()
        .filter((ds) => !!ds)
        .sort((a, b) => ((a.createdAt || 0) > (b.createdAt || 0) ? -1 : 1))
        .map((ds) => {
          return {
            key: ds.id,
            id: ds.id,
            label: ds.type,
            isInvalid: this.isInvalidStorage(ds),
            removeable: this.isRemovableStorage(ds),
            icon: DATA_SOURCE_ICONS_NAMES[ds.type as keyof typeof DATA_SOURCE_ICONS_NAMES],
          };
        });
    },
    isValidationError(): boolean {
      return this.tabs.some((tab) => tab.isInvalid);
    },
  },
  methods: {
    addOneOffStorage(storageType: string): void {
      const namesMap: Record<string, AssetKind> = {
        [DATA_SOURCES_TYPES_TO_NAMES.NFS]: AssetKind.Nfs,
        [DATA_SOURCES_TYPES_TO_NAMES.HOST_PATH]: AssetKind.HostPath,
        [DATA_SOURCES_TYPES_TO_NAMES.S3]: AssetKind.S3,
        [DATA_SOURCES_TYPES_TO_NAMES.GIT]: AssetKind.Git,
        [DATA_SOURCES_TYPES_TO_NAMES.SECRET_VOLUME]: AssetKind.SecretVolume,
        [DATA_SOURCES_TYPES_TO_NAMES.CONFIG_MAP]: AssetKind.ConfigMap,
        [DATA_SOURCES_TYPES_TO_NAMES.PVC]: AssetKind.Pvc,
      };

      const storageKeyMap: Record<string, keyof typeof this.workloadStorage> = {
        [DATA_SOURCES_TYPES_TO_NAMES.NFS]: "nfs",
        [DATA_SOURCES_TYPES_TO_NAMES.HOST_PATH]: "hostPath",
        [DATA_SOURCES_TYPES_TO_NAMES.S3]: "s3",
        [DATA_SOURCES_TYPES_TO_NAMES.GIT]: "git",
        [DATA_SOURCES_TYPES_TO_NAMES.SECRET_VOLUME]: "secretVolume",
        [DATA_SOURCES_TYPES_TO_NAMES.CONFIG_MAP]: "configMapVolume",
        [DATA_SOURCES_TYPES_TO_NAMES.PVC]: "pvc",
      };

      const type = namesMap[storageType];
      const storageKey = storageKeyMap[storageType];

      if (type && storageKey) {
        const storageToAdd = { id: makeId(), type, createdAt: Date.now() };
        this.$emit("update", {
          ...this.workloadStorage,
          [storageKey]: [storageToAdd, ...(this.workloadStorage[storageKey] || [])],
        });
        this.selectedId = storageToAdd.id;
      }
    },
    isRemovableStorage(
      ds:
        | IUINfsInstance
        | IUIHostPathInstance
        | IUIS3Instance
        | IUIGitInstance
        | IUISecretVolumeInstance
        | IUIConfigMapInstance
        | IUIPvcInstance,
    ): boolean {
      switch (ds.type) {
        case AssetKind.Nfs:
          return !this.policyRules?.nfs?.instances?.locked?.includes(ds.name || "");
        case AssetKind.HostPath:
          return !this.policyRules?.hostPath?.instances?.locked?.includes(ds.name || "");
        case AssetKind.S3:
          return !this.policyRules?.s3?.instances?.locked?.includes(ds.name || "");
        case AssetKind.Git:
          return !this.policyRules?.git?.instances?.locked?.includes(ds.name || "");
        case AssetKind.SecretVolume:
          return !this.policyRules?.secretVolume?.instances?.locked?.includes(ds.name || "");
        case AssetKind.ConfigMap:
          return !this.policyRules?.configMapVolume?.instances?.locked?.includes(ds.name || "");
        case AssetKind.Pvc:
          return !this.policyRules?.pvc?.instances?.locked?.includes(ds.name || "");
        default:
          return true;
      }
    },
    isInvalidStorage(
      ds:
        | IUINfsInstance
        | IUIS3Instance
        | IUIPvcInstance
        | IUIHostPathInstance
        | IUIGitInstance
        | IUISecretVolumeInstance
        | IUIConfigMapInstance,
    ): boolean {
      switch (ds.type) {
        case AssetKind.Nfs:
          return this.isInvalidNfs(ds);
        case AssetKind.HostPath:
          return this.isInvalidHostPath(ds);
        case AssetKind.S3:
          return this.isInvalidS3(ds);
        case AssetKind.Git:
          return this.isInvalidGit(ds);
        default:
          return this.isInvalidContainerPath(ds);
      }
    },
    isInvalidNfs(ds: IUINfsInstance): boolean {
      const validServer: boolean = isNotEmpty(ds.server || "");
      const validPath: boolean = isNotEmpty(ds.path || "") && isValidDirectoryPath(ds.path || "");
      const validMountPath: boolean = isNotEmpty(ds.mountPath || "") && isValidDirectoryPath(ds.mountPath || "");
      return !validServer || !validPath || !validMountPath;
    },
    isInvalidHostPath(ds: IUIHostPathInstance): boolean {
      const validPath: boolean = isNotEmpty(ds.path || "") && isValidDirectoryPath(ds.path || "");
      const validMountPath: boolean = isNotEmpty(ds.mountPath || "") && isValidDirectoryPath(ds.mountPath || "");
      return !validPath || !validMountPath;
    },
    isInvalidS3(ds: IUIS3Instance): boolean {
      const validBucket: boolean = isNotEmpty(ds.bucket || "");
      const validPath: boolean = isNotEmpty(ds.path || "") && isValidDirectoryPath(ds.path || "");
      const validUrl: boolean = isNotEmpty(ds.url || "");
      return !validBucket || !validPath || !validUrl;
    },
    isInvalidGit(ds: IUIGitInstance): boolean {
      const validUrl: boolean = isNotEmpty(ds.repository || "") && isValidUrl(ds.repository || "");
      const validPath: boolean = isNotEmpty(ds.path || "") && isValidDirectoryPath(ds.path || "");
      return !validUrl || !validPath;
    },
    isInvalidContainerPath(
      ds:
        | IUINfsInstance
        | IUIS3Instance
        | IUIPvcInstance
        | IUIHostPathInstance
        | IUIGitInstance
        | IUISecretVolumeInstance
        | IUIConfigMapInstance,
    ) {
      if (!ds) {
        return false;
      }
      const containerPathKey = DATA_SOURCE_KIND_TO_CONTAINER_KEY[ds.type as TDataSourceKinds] as keyof typeof ds;
      const containerPath = ds[containerPathKey];

      return this.notEmpty(containerPath || "") !== true || this.isDirectoryPath(containerPath || "") !== true;
    },
    updateNfs(data: IUINfsInstance) {
      this.$emit("update", {
        ...this.workloadStorage,
        nfs: this.workloadStorage.nfs?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    updateHostPath(data: IUIHostPathInstance) {
      this.$emit("update", {
        ...this.workloadStorage,
        hostPath: this.workloadStorage.hostPath?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    updateS3(data: IUIS3Instance) {
      this.$emit("update", {
        ...this.workloadStorage,
        s3: this.workloadStorage.s3?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    updateGit(data: IUIGitInstance) {
      this.$emit("update", {
        ...this.workloadStorage,
        git: this.workloadStorage.git?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    updateSecretVolume(data: IUISecretVolumeInstance) {
      this.$emit("update", {
        ...this.workloadStorage,
        secretVolume: this.workloadStorage.secretVolume?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    updateConfigMap(data: IUIConfigMapInstance) {
      this.$emit("update", {
        ...this.workloadStorage,
        configMapVolume: this.workloadStorage.configMapVolume?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    updatePvc(data: IUIPvcInstance) {
      this.$emit("update", {
        ...this.workloadStorage,
        pvc: this.workloadStorage.pvc?.map((ds) => (ds.id === data.id ? data : ds)),
      });
    },
    removeStorage(id: string, type: string) {
      const storage = this.workloadStorage[type as keyof IUIStorageVolumesExcludedFields];
      if (!storage) {
        return;
      }
      this.$emit("update", {
        ...this.workloadStorage,
        [type]: storage.filter((ds) => ds.id !== id),
      });
    },
    isDirectoryPath(val: string): boolean | string {
      return isValidDirectoryPath(val) || errorMessages.INVALID_DIRECTORY_PATH;
    },
    notEmpty(val: string): boolean | string {
      return isNotEmpty(val) || errorMessages.PATH_NOT_EMPTY;
    },
    isIncomplete(val: boolean): boolean | string {
      return !val ? true : errorMessages.AT_LEAST_ONE_TARGET_LOCATION_ISSUE;
    },
  },
  watch: {
    workloadStorage: {
      handler() {
        const selectedTab = this.tabs.find((tab) => tab.id === this.selectedId);
        // Selecting the first tab if there is no selected tab
        if (!selectedTab && this.tabs.length > 0) {
          this.selectedId = this.tabs[0]?.id;
        }
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss">
.hybrid-data-source-section {
  .data-source-tabs {
    border-bottom: 1px solid $black-10;
    overflow: scroll;
    .q-tab {
      position: relative;
      .close-btn {
        color: $black-54;
        font-size: 12px;
        display: none;
      }

      &.add-one-off-tab {
        padding: 0 8px;
      }

      &:hover {
        .close-btn {
          display: block;
          position: absolute;
          right: -12px;
        }
      }
    }
  }
  .q-tabs__arrow {
    font-size: 18px;
  }
  .q-tab__icon {
    font-size: 18px;
  }
}
</style>
