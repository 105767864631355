// models
import {
  EAccessOptions,
  type IConnectionAccess,
} from "@/components/environment/connection-access/connection-access-modal/connection-access-modal.model";
import {
  EHuggingFaceEnvVariableNames,
  EInferenceType,
  ENimEnvVariableNames,
  LLM_IMAGE_FOR_HUGGING_FACE,
  NIM_IMAGE_PREFIX,
  SERVING_PORT_SERVER_PORT,
} from "@/models/inference.model";
import {
  EWorkloadType,
  type IUIInferenceCreation,
  type IUIWorkloadCreation,
  type IUIWorkloadSpecificEnv,
} from "@/models/workload.model";
import type { IUIWorkloadEnvSectionModel } from "@/components/section/environment-section/environment-section.model";
import type { IComputeSectionData } from "@/components/section/compute-resource-section/compute-resource-section.models";
import type { Project, Resources } from "@/swagger-models/org-unit-service-client";
import {
  type InferenceSpecificRunParams,
  SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum,
  type WorkloadCreationRequest,
  type EnvironmentAsset,
  type EnvironmentVariableOfAsset,
  type InferenceCreationRequestV2,
  type ModelAsset,
  type ModelInferenceCreationRequest,
  type ModelInferenceCreationRequestV2,
  type SpecificRunServingPortAccessServingPortAccess,
  type ComputeFields,
  type PvcInstance,
  type HostPathInstance,
  type NfsInstance,
  type CommonStorageFields,
} from "@/swagger-models/assets-service-client";
import { ENimAccessModelMethod } from "@/components/section/nim-model-section/nim-access-model-selector/nim-access-model-selector.model";
import {
  Source,
  Phase,
  type Workload,
  type Inference,
  type InferenceCreationRequest,
  type InferenceSpecSpec,
  type EnvironmentVariableSecret,
  type EnvironmentVariable,
} from "@/swagger-models/workloads-client";
import type { IItemizedListItem } from "@/components/common/runai-itemized-list/runai-itemized-list.model";
import type { IAutoScaleData } from "@/components/section/compute-resource-section/auto-scale-section";
import { type TWorkloadsToConvert, workloadUtil } from "@/utils/workload.util/workload.util";
import type { IUIVolume } from "@/models/data-source.model";
import type { ClusterDependencyStatus, DisplayedCluster } from "@/swagger-models/cluster-service-client";

// utils
import { fallbackDefaultIfNullOrUndefined, omit, pick } from "@/utils/common.util/common.util";
import { workloadCreateFormUtil } from "@/utils/workload-create-form.util/workload-create-form.util";
import { EServerType } from "@/components/section/hugging-face-environment-section/server-type";

export const inferenceWorkloadUtil = {
  getEmptyUIInferenceCreation,
  getEmptyUIModelCatalogInferenceCreation,
  getEmptyUINimInferenceCreation,
  getEmptyUIHfInferenceCreation,
  getInferenceComputeResourceData,
  getEnvironmentRunParamsData,
  createModelCatalog,
  getHuggingFaceTokenValue,
  getEnvironmentVariableValue,
  excludeEnvironmentVariablesByName,
  getModelInferenceCreationRequest,
  getModelInferenceCreationRequestV2,
  getInferenceCreationRequestV2,
  convertInferenceToWorkloadUI,
  getInferenceWorkloadCreationRequest,
  getInferenceRequestV2SpecificRunParams,
  getNimInferenceWorkloadCreationRequest,
  getHFInferenceWorkloadCreationRequest,
  isLatencyEnableOnCluster,
  convertInferenceToWorkload,

  // private functions
  _getAuthorizationType,
  _servingPortAccess,
  _getServingPortAccessOption,
  _servingPortInternalToolInfo,
  _getFlatWorkloadData,
  _getFlatInferenceWorkloadData,
  _addNimEnvironmentVariables,
  _fillDatasourceData,
};

function convertInferenceToWorkload(inferenceWorkload: Inference, tenantId: number): Workload {
  return {
    source: Source.ControlPlane,
    conditions: [{ status: "", type: "", lastTransitionTime: null }],
    priorityClassName: "",
    type: EWorkloadType.Inference,
    name: inferenceWorkload.name,
    id: inferenceWorkload.workloadId,
    clusterId: inferenceWorkload.clusterId,
    projectName: "",
    projectId: inferenceWorkload.projectId,
    departmentName: "",
    departmentId: inferenceWorkload.departmentId || "",
    namespace: "",
    createdAt: inferenceWorkload.createdAt,
    phase: inferenceWorkload.actualPhase || Phase.Creating,
    k8sPhase: "",
    tenantId: tenantId,
    runningPods: 0,
    phaseUpdatedAt: "",
    k8sPhaseUpdatedAt: "",
    updatedAt: "",
    deletedAt: null,
    submittedBy: inferenceWorkload.createdBy,
    priority: null,
  };
}

function getEmptyUINimInferenceCreation(specificEnv?: IUIWorkloadSpecificEnv): IUIInferenceCreation {
  const uiWorkload: IUIWorkloadCreation = getEmptyUIInferenceCreation(specificEnv);
  const modelCreateUi: IUIInferenceCreation = {
    ...uiWorkload,
    image: "",
    inferenceType: EInferenceType.Nim,
    accessFromStorage: false,
    accessModelMethod: ENimAccessModelMethod.Manually,
  };
  return modelCreateUi;
}

function getEmptyUIModelCatalogInferenceCreation(specificEnv?: IUIWorkloadSpecificEnv): IUIInferenceCreation {
  const uiWorkload: IUIWorkloadCreation = getEmptyUIInferenceCreation(specificEnv);
  const modelCreateUi: IUIInferenceCreation = {
    ...uiWorkload,
    modelId: "",
    inferenceType: EInferenceType.RunaiModel,
  };
  return modelCreateUi;
}

function getEmptyUIHfInferenceCreation(specificEnv?: IUIWorkloadSpecificEnv): IUIInferenceCreation {
  const uiInferenceWorkload: IUIInferenceCreation = getEmptyUIInferenceCreation(specificEnv);
  uiInferenceWorkload.serverType = EServerType.VLLM;
  uiInferenceWorkload.image = LLM_IMAGE_FOR_HUGGING_FACE;
  uiInferenceWorkload.inferenceType = EInferenceType.HuggingFace;

  return uiInferenceWorkload;
}

function getEmptyUIInferenceCreation(specificEnv?: IUIWorkloadSpecificEnv): IUIInferenceCreation {
  const MIN_REPLICAS_DEFAULT_VALUE = 1;
  const MAX_REPLICAS_DEFAULT_VALUE = 1;

  const uiWorkload: IUIWorkloadCreation = workloadUtil.getEmptyUIWorkloadCreation(specificEnv);
  const uiInferenceWorkload: IUIInferenceCreation = {
    ...uiWorkload,
    specificEnv: {
      ...omit(uiWorkload.specificEnv, ["backoffLimit"]),
      autoScaleData: {
        minReplicas: MIN_REPLICAS_DEFAULT_VALUE,
        maxReplicas: MAX_REPLICAS_DEFAULT_VALUE,
        thresholdMetric: undefined,
        thresholdValue: undefined,
        scaleToZeroRetentionSeconds: undefined,
      },
      servingPortAccess: {
        authorizedUsers: null,
        authorizedGroups: null,
        accessOption: EAccessOptions.PUBLIC,
      },
    },
    inferenceType: EInferenceType.AssetsBased,
  };

  return uiInferenceWorkload;
}

function getInferenceComputeResourceData(
  workload: IUIWorkloadCreation,
  projectNodepools: Array<Resources> | undefined | null = [],
): IComputeSectionData {
  const computeData: IComputeSectionData = workloadCreateFormUtil.getComputeResourceData(workload, projectNodepools);
  computeData.autoScaleData = workload.specificEnv.autoScaleData;
  return computeData;
}

function getEnvironmentRunParamsData(workload: IUIWorkloadCreation): IUIWorkloadEnvSectionModel {
  const envSectionData: IUIWorkloadEnvSectionModel = workloadCreateFormUtil.getEnvironmentRunParamsData(workload);
  envSectionData.servingPortAccess = workload.specificEnv.servingPortAccess;
  return envSectionData;
}

function getHuggingFaceTokenValue(environmentVariables: Array<EnvironmentVariableOfAsset> = []): string {
  const tokenEnv: EnvironmentVariableOfAsset | undefined = environmentVariables.find(
    (env: EnvironmentVariableOfAsset) => env.name === EHuggingFaceEnvVariableNames.HF_TOKEN && !env.credential,
  );

  return tokenEnv?.value || "";
}

function getEnvironmentVariableValue(
  keyName: string,
  environmentVariables: Array<EnvironmentVariableOfAsset> = [],
): string {
  const modelEnv: EnvironmentVariableOfAsset | undefined = environmentVariables.find(
    (env: EnvironmentVariableOfAsset) => env.name === keyName,
  );

  return modelEnv?.value || "";
}

function _replaceEnvironmentVariable(
  environmentVariables: Array<EnvironmentVariable> | undefined | null,
  name: string,
  value?: string,
  secret?: EnvironmentVariableSecret,
): Array<EnvironmentVariableOfAsset> {
  let envs = environmentVariables || [];
  envs = envs.filter((env: EnvironmentVariable) => env.name !== name);

  secret ? envs.push({ name, secret }) : envs.push({ name, value });
  return envs;
}

function excludeEnvironmentVariablesByName(
  keyNames: string | Array<string>,
  environmentVariables: Array<EnvironmentVariableOfAsset> = [],
): Array<EnvironmentVariableOfAsset> {
  const keyNamesArray: Array<string> = Array.isArray(keyNames) ? keyNames : [keyNames];
  const keyNamesMap = new Set<string>(keyNamesArray);
  return environmentVariables.filter((env: EnvironmentVariableOfAsset) => env.name && !keyNamesMap.has(env.name));
}

function getNimInferenceWorkloadCreationRequest(
  workload: IUIInferenceCreation,
  compute: ComputeFields,
  storage: PvcInstance | HostPathInstance | NfsInstance | undefined,
  kind: keyof CommonStorageFields | undefined,
  genericSecretName?: string,
): InferenceCreationRequest {
  const workloadCreationRequest: InferenceCreationRequest = _getFlatInferenceWorkloadData(workload, compute);

  if (!workload.modelName) {
    throw new Error("Model name is missing!");
  }

  // nim specific params
  if (!workloadCreationRequest.spec) {
    workloadCreationRequest.spec = {};
  }

  workloadCreationRequest.spec.image = `${NIM_IMAGE_PREFIX}/${workload.modelName}`;
  workloadCreationRequest.spec.security = {
    ...workloadCreationRequest.spec.security,
    runAsGid: 1000,
    runAsUid: 1000,
  };

  workloadCreationRequest.spec.compute = {
    ...workloadCreationRequest.spec.compute,
    largeShmRequest: true,
  };

  workloadCreationRequest.spec.servingPort = {
    ...workloadCreationRequest.spec.servingPort,
    container: SERVING_PORT_SERVER_PORT,
    protocol: "http",
  };

  if (storage) {
    workloadCreationRequest.spec = _fillDatasourceData(workloadCreationRequest.spec, storage, kind);
  }

  workloadCreationRequest.spec.environmentVariables = _addNimEnvironmentVariables(
    workloadCreationRequest.spec.environmentVariables || [],
  );

  if (workload.profileNameOrHash) {
    workloadCreationRequest.spec.environmentVariables = _replaceEnvironmentVariable(
      workloadCreationRequest.spec.environmentVariables,
      ENimEnvVariableNames.NimModelProfile,
      workload.profileNameOrHash,
    );
  }

  if (workload.token) {
    workloadCreationRequest.spec.environmentVariables = _replaceEnvironmentVariable(
      workloadCreationRequest.spec.environmentVariables,
      ENimEnvVariableNames.NgcApiKey,
      workload.token,
    );
  }

  if (genericSecretName) {
    workloadCreationRequest.spec.environmentVariables = _replaceEnvironmentVariable(
      workloadCreationRequest.spec.environmentVariables,
      ENimEnvVariableNames.NgcApiKey,
      undefined,
      {
        name: genericSecretName,
        key: ENimEnvVariableNames.NgcApiKey,
      },
    );
  }

  return workloadCreationRequest;
}

function getHFInferenceWorkloadCreationRequest(
  workload: IUIInferenceCreation,
  compute: ComputeFields,
  storage: PvcInstance | HostPathInstance | NfsInstance | undefined,
  kind: keyof CommonStorageFields | undefined,
  genericSecretName?: string,
): InferenceCreationRequest {
  const workloadCreationRequest: InferenceCreationRequest = _getFlatInferenceWorkloadData(workload, compute);

  // hf specific params
  if (!workloadCreationRequest.spec) {
    workloadCreationRequest.spec = {};
  }

  if (!workload.serverType) {
    throw new Error("Missing server type: The workload object must include a valid 'serverType' property.");
  }

  const modelFlag = workload.serverType === EServerType.TGI ? "--model-id" : "--model";
  workloadCreationRequest.spec.args = `${modelFlag} ${workload.modelName} ${
    workloadCreationRequest.spec.args || ""
  }`.trim();
  workloadCreationRequest.spec.image = workload.image;
  workloadCreationRequest.spec.servingPort = {
    ...workloadCreationRequest.spec.servingPort,
    container: SERVING_PORT_SERVER_PORT,
    protocol: "http",
  };

  if (storage) {
    workloadCreationRequest.spec = _fillDatasourceData(workloadCreationRequest.spec, storage, kind);
  }

  if (workloadCreationRequest.spec.compute && workloadCreationRequest.spec.compute.gpuDevicesRequest === 0) {
    workloadCreationRequest.spec.compute.gpuDevicesRequest = undefined;
  }

  if (!workloadCreationRequest.spec.environmentVariables) {
    workloadCreationRequest.spec.environmentVariables = [];
  }

  if (workload.token) {
    workloadCreationRequest.spec.environmentVariables = excludeEnvironmentVariablesByName(
      EHuggingFaceEnvVariableNames.HF_TOKEN,
      workloadCreationRequest.spec.environmentVariables,
    );

    workloadCreationRequest.spec.environmentVariables.push({
      name: EHuggingFaceEnvVariableNames.HF_TOKEN,
      value: workload.token || "",
    });
  }

  if (genericSecretName) {
    workloadCreationRequest.spec.environmentVariables = excludeEnvironmentVariablesByName(
      EHuggingFaceEnvVariableNames.HF_TOKEN,
      workloadCreationRequest.spec.environmentVariables,
    );
    workloadCreationRequest.spec.environmentVariables.push({
      name: EHuggingFaceEnvVariableNames.HF_TOKEN,
      secret: {
        name: genericSecretName,
        key: EHuggingFaceEnvVariableNames.HF_TOKEN,
      },
    });
  }

  return workloadCreationRequest;
}

function _fillDatasourceData(
  nimWorkloadSpec: InferenceSpecSpec,
  storage: PvcInstance | HostPathInstance | NfsInstance | undefined,
  kind: keyof CommonStorageFields | undefined,
): InferenceSpecSpec {
  if (kind) {
    nimWorkloadSpec.storage = {
      [kind]: [storage],
    };
  }

  nimWorkloadSpec.environmentVariables = excludeEnvironmentVariablesByName(
    ENimEnvVariableNames.NimCachePath,
    nimWorkloadSpec.environmentVariables || [],
  );
  const path: string | undefined | null = storage?.path;

  if (path) {
    nimWorkloadSpec.environmentVariables?.push({ name: ENimEnvVariableNames.NimCachePath, value: path });
  }

  return nimWorkloadSpec;
}

function _addNimEnvironmentVariables(
  environmentVariables: Array<EnvironmentVariableOfAsset>,
): Array<EnvironmentVariableOfAsset> {
  environmentVariables = excludeEnvironmentVariablesByName(
    [
      ENimEnvVariableNames.OutlinesCacheDir,
      ENimEnvVariableNames.NimServerPort,
      ENimEnvVariableNames.NimJsonlLogging,
      ENimEnvVariableNames.NimLogLevel,
    ],
    environmentVariables,
  );

  environmentVariables?.push(
    { name: ENimEnvVariableNames.OutlinesCacheDir, value: "/tmp/outlines" },
    { name: ENimEnvVariableNames.NimServerPort, value: String(SERVING_PORT_SERVER_PORT) },
    { name: ENimEnvVariableNames.NimJsonlLogging, value: "1" },
    { name: ENimEnvVariableNames.NimLogLevel, value: "INFO" },
  );

  return environmentVariables;
}

function _getFlatInferenceWorkloadData(
  workload: IUIInferenceCreation,
  computeFields: ComputeFields,
  environment?: EnvironmentAsset,
): InferenceCreationRequest {
  const specificEnv = workload.specificEnv;
  const workloadCreationRequest: InferenceCreationRequest = _getFlatWorkloadData(workload, computeFields, environment);

  if (workloadCreationRequest.spec && specificEnv.autoScaleData) {
    const autoScaleData: IAutoScaleData = specificEnv.autoScaleData;
    workloadCreationRequest.spec.autoscaling = {
      minReplicas: autoScaleData.minReplicas,
      maxReplicas: autoScaleData.maxReplicas,
      scaleToZeroRetentionSeconds: autoScaleData.scaleToZeroRetentionSeconds || undefined,
      metric: autoScaleData.thresholdMetric || undefined,
      metricThreshold: autoScaleData.thresholdValue || undefined,
    };
    workloadCreationRequest.spec.servingPort = _servingPortAccess(specificEnv.servingPortAccess);
  }

  return workloadCreationRequest;
}

function _getFlatWorkloadData(
  workload: IUIInferenceCreation,
  computeFields: ComputeFields,
  environment?: EnvironmentAsset,
): InferenceCreationRequest {
  const specificEnv = workload.specificEnv || {};
  const workloadCreationRequest: InferenceCreationRequest = {
    name: workload.name,
    projectId: workload.projectId.toString(),
    clusterId: workload.clusterId,
    spec: {
      command: specificEnv.command || undefined,
      args: specificEnv.args || undefined,
      nodeType: specificEnv.nodeType || undefined,
      nodePools: specificEnv.nodePools,
      podAffinity: specificEnv.podAffinity || undefined,
      environmentVariables: specificEnv.environmentVariables,
      annotations: specificEnv.annotations?.map((env: IItemizedListItem) => pick(env, "name", "value", "exclude")),
      labels: specificEnv.labels?.map((env: IItemizedListItem) => pick(env, "name", "value", "exclude")),
      tolerations: specificEnv.tolerations,
      compute: computeFields,
    },
  };

  if (environment) {
    workloadCreationRequest.spec = {
      ...workloadCreationRequest.spec,
      image: environment.spec.image || undefined,
      imagePullPolicy: environment.spec.imagePullPolicy || undefined,
      workingDir: environment.spec.workingDir || undefined,
      createHomeDir: environment.spec.createHomeDir || undefined,
    };
  }
  return workloadCreationRequest;
}

function createModelCatalog(
  inference: IUIInferenceCreation,
  selectedProject: Project,
  selectedModelAsset: ModelAsset,
  createdComputeId: string,
  environment: EnvironmentAsset | null,
  supportingServingPortAccess: boolean,
): IUIInferenceCreation {
  const modelCatalog: IUIInferenceCreation = {
    modelId: selectedModelAsset.meta.id,
    clusterId: inference.clusterId,
    projectId: inference.projectId,
    namespace: selectedProject?.status.namespace || inference.namespace,
    name: inference.name,
    assets: {
      compute: createdComputeId || "",
      environment: selectedModelAsset.spec.assets?.environment || null,
    },
    specificEnv: {
      ...inference.specificEnv,
      environmentVariables:
        inference.specificEnv.environmentVariables || environment?.spec.environmentVariables || undefined,
    },
    inferenceType: EInferenceType.RunaiModel,
  };

  if (!modelCatalog.specificEnv.nodePools && selectedProject.effective.defaultNodePools) {
    modelCatalog.specificEnv.nodePools = selectedProject.effective.defaultNodePools;
  }

  // Remove servingPortAccess if not supported
  if (!supportingServingPortAccess) {
    delete modelCatalog.specificEnv.servingPortAccess;
  }

  return modelCatalog;
}

/// ---->
function getInferenceCreationRequestV2(
  inference: IUIWorkloadCreation,
  supportingServingPortAccess = false,
  workloadVolumes?: Array<string>,
): InferenceCreationRequestV2 {
  if (!inference.assets.environment) throw "Environment details are missing";
  const workloadCreationRequest: InferenceCreationRequestV2 = {
    meta: {
      name: inference.name,
      projectId: inference.projectId.toString(),
      clusterId: inference.clusterId,
    },
    assets: workloadUtil.getWorkloadAssetsFromUIWorkloadAssets(inference.assets, workloadVolumes),
    specificRunParams: getInferenceRequestV2SpecificRunParams(inference.specificEnv, supportingServingPortAccess),
  };
  return workloadCreationRequest;
}

function getModelInferenceCreationRequest(
  modelSpecCreate: IUIInferenceCreation,
  supportingServingPortAccess = false,
): ModelInferenceCreationRequest {
  if (!modelSpecCreate.assets.compute) throw "Compute resource is missing";
  if (!modelSpecCreate.modelId) throw "Model ID is missing";

  const uiWorkload: ModelInferenceCreationRequest = {
    name: modelSpecCreate.name,
    clusterId: modelSpecCreate.clusterId,
    projectId: modelSpecCreate.projectId,
    namespace: modelSpecCreate.namespace,
    modelId: modelSpecCreate.modelId,
    assets: {
      compute: modelSpecCreate.assets.compute,
    },
    specificEnv: getInferenceRequestV2SpecificRunParams(modelSpecCreate.specificEnv, supportingServingPortAccess),
  };

  return uiWorkload;
}

function getModelInferenceCreationRequestV2(
  modelSpecCreate: IUIInferenceCreation,
  supportingServingPortAccess = false,
): ModelInferenceCreationRequestV2 {
  if (!modelSpecCreate.assets.compute) throw "Compute resource is missing";
  if (!modelSpecCreate.modelId) throw "Model ID is missing";

  return {
    meta: {
      name: modelSpecCreate.name,
      clusterId: modelSpecCreate.clusterId,
      projectId: String(modelSpecCreate.projectId),
    },
    assets: {
      model: modelSpecCreate.modelId,
      compute: modelSpecCreate.assets.compute || "",
    },
    specificRunParams: getInferenceRequestV2SpecificRunParams(modelSpecCreate.specificEnv, supportingServingPortAccess),
  };
}

function convertInferenceToWorkloadUI(
  workload: TWorkloadsToConvert,
  inferenceType: EInferenceType,
  supportingServingPortAccess = false,
  uiVolumes?: Array<IUIVolume>,
): IUIInferenceCreation {
  const baseWorkload: IUIWorkloadCreation = workloadUtil.convertWorkloadToWorkloadUI(workload, uiVolumes);
  const inferenceUI: IUIInferenceCreation = {
    ...baseWorkload,
    inferenceType,
  };

  const autoScaling = workload.specificRunParams?.autoScaling;
  inferenceUI.specificEnv.autoScaleData = {
    minReplicas: fallbackDefaultIfNullOrUndefined(autoScaling?.minReplicas, 1),
    maxReplicas: fallbackDefaultIfNullOrUndefined(autoScaling?.maxReplicas, 1),
    thresholdMetric: autoScaling?.thresholdMetric || undefined,
    thresholdValue: autoScaling?.thresholdValue || undefined,
    scaleToZeroRetentionSeconds: autoScaling?.scaleToZeroRetentionSeconds || undefined,
  };

  if (supportingServingPortAccess) {
    inferenceUI.specificEnv.servingPortAccess = _servingPortInternalToolInfo(
      workload.specificRunParams?.servingPortAccess,
    );
  }

  // delete inferenceUI.specificEnv.allowOverQuota;
  return {
    ...inferenceUI,
    inferenceType: inferenceType || EInferenceType.AssetsBased,
  };
}

function _servingPortInternalToolInfo(
  servingPortAccess: SpecificRunServingPortAccessServingPortAccess | undefined | null,
): IConnectionAccess {
  return {
    accessOption: _getServingPortAccessOption(servingPortAccess),
    authorizedGroups: servingPortAccess?.authorizedGroups,
    authorizedUsers: servingPortAccess?.authorizedUsers,
  };
}

function _getServingPortAccessOption(
  servingPortAccess: SpecificRunServingPortAccessServingPortAccess | undefined | null,
): EAccessOptions {
  let authorizationType: EAccessOptions = EAccessOptions.PUBLIC;
  if (!servingPortAccess) return authorizationType;
  switch (servingPortAccess.authorizationType) {
    case SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.Public:
      authorizationType = EAccessOptions.PUBLIC;
      break;
    case SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.AuthenticatedUsers:
      authorizationType = EAccessOptions.EVERYONE;
      break;
    case SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.AuthorizedUsersOrGroups:
      if (servingPortAccess.authorizedGroups) {
        authorizationType = EAccessOptions.GROUPS;
      } else {
        authorizationType = EAccessOptions.SPECIFIC_USERS;
      }
  }

  return authorizationType;
}

function getInferenceRequestV2SpecificRunParams(
  specificEnv: IUIWorkloadSpecificEnv,
  supportingServingPortAccess = false,
): InferenceSpecificRunParams {
  const retSpecificEnv: InferenceSpecificRunParams = workloadUtil.processCommonSpecificEnv(specificEnv, [
    "allowOverQuota",
    "backoffLimit",
    "autoScaleData",
    "servingPortAccess",
  ]);

  retSpecificEnv.autoScaling = specificEnv.autoScaleData;

  if (supportingServingPortAccess) {
    retSpecificEnv.servingPortAccess = _servingPortAccess(specificEnv.servingPortAccess);
  }

  return retSpecificEnv;
}

function getInferenceWorkloadCreationRequest(
  workload: IUIInferenceCreation,
  supportingServingPortAccess = false,
  workloadVolumes?: Array<string>,
): WorkloadCreationRequest {
  const workloadCreationRequest: WorkloadCreationRequest = workloadUtil.getWorkloadCreationRequest(
    workload,
    workloadVolumes,
  );

  workloadCreationRequest.specificEnv = {
    ...workloadCreationRequest.specificEnv,
    autoScaling: workload.specificEnv.autoScaleData,
  };

  if (supportingServingPortAccess && workload.specificEnv.servingPortAccess) {
    workloadCreationRequest.specificEnv.servingPortAccess = _servingPortAccess(workload.specificEnv.servingPortAccess);
  }

  // Inference does not support backoffLimit
  delete workloadCreationRequest.specificEnv.backoffLimit;

  return workloadCreationRequest;
}

function isLatencyEnableOnCluster(currCluster: DisplayedCluster): boolean {
  if (!currCluster.status?.dependencies?.optional?.knative) return false;
  const dependencyStatus: Record<string, ClusterDependencyStatus> | undefined =
    currCluster.status.dependencies.optional.knative.components;
  return (dependencyStatus && dependencyStatus["hpa"] && dependencyStatus["hpa"].available) || false;
}

function _servingPortAccess(
  servingPortAccess: IConnectionAccess | null | undefined,
): SpecificRunServingPortAccessServingPortAccess {
  return {
    authorizationType: _getAuthorizationType(servingPortAccess),
    authorizedGroups: servingPortAccess?.authorizedGroups,
    authorizedUsers: servingPortAccess?.authorizedUsers,
  };
}

function _getAuthorizationType(
  servingPortAccess: IConnectionAccess | null | undefined,
): SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum | undefined {
  let authorizationType: SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum | undefined = undefined;

  if (!servingPortAccess?.accessOption) return authorizationType;

  switch (servingPortAccess.accessOption) {
    case EAccessOptions.PUBLIC:
      // set to undefined since no need to send it to the server
      break;
    case EAccessOptions.EVERYONE:
      authorizationType = SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.AuthenticatedUsers;
      break;
    case EAccessOptions.GROUPS:
    case EAccessOptions.SPECIFIC_USERS:
      authorizationType = SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.AuthorizedUsersOrGroups;
  }

  return authorizationType;
}
